<!--修改绑定手机3-->
<template>
  <div class="AlterBindPhone1">
    <br>
    <div class="safe" @click.prevent="returnSafe">&lt; 安全中心</div>
    <div class="AlterBindPhone2">
      <div class="AlterBindPhone3">
        <br><br><br><br>
        <div class="AlterBindPhone4">
          <div id="order1">
            <div class="order1">√</div>
            <div class="orderText">验证原手机</div>
          </div>
          <div>
            <hr class="hr1">
            <div class="hidden">占位</div>
          </div>
          <div id="order2">
            <div class="order2">√</div>
            <div class="orderText">验证新手机</div>
          </div>
          <div>
            <hr class="hr2">
            <div class="hidden">占位</div>
          </div>
          <div id="order3">
            <div class="order3">3</div>
            <div class="orderText">操作成功</div>
          </div>
        </div>
        <br><br><br><br>
        <div class="img">
          图片<img src="">
        </div>
        <br><br>
        <div>
          <h2>绑定手机修改成功!</h2>
        </div>
        <br><br>
        <div>
          <a ref=""><button class="next" @click.prevent="alterBindPhoNumNext">返回安全中心</button></a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AlterBindPhone2",
  components: {},
  mounted() {
  },

  methods: {
    //修改绑定手机
    alterBindPhoNumNext(){
      //跳转页面返回安全中心
      this.$router.push({name:'安全中心'});
    },
    //返回安全中心
    returnSafe(){
      //跳转页面返回安全中心
      this.$router.push({name:'安全中心'});
    },
  },

  data() {
    return {

    }
  },

};
</script>


<style scoped>
div{
  text-align: center;
}
.AlterBindPhone1{
  width: 1000px;
  margin:0px auto;
}
.safe{
  text-align: left;
}
.order1,.order2,.order3{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #C19B71;
}
#order1,#order2,#order3{
  color: #C19B71;
}
.order1,.order2,.order3{
  line-height:60px;
  font-size: x-large;
}
.order1,.order2{
  background-color:#C19B71;
  color: white;
}
.AlterBindPhone4{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.orderText{
  font-size: smaller;
}
hr{
  width: 200px;
  background-color: #000000;
  height: 1px;/*设置高度会添加新设置的颜色，但hr中本来的灰色还是存在的。 */
  border: none;/*去掉hr中本来的边框，即原本的灰色。 */
}
hr{
  background-color: #C19B71;
}
.hidden{
  visibility: hidden;
}
span{
  color: #c5231c;
}
input{
  width: 300px;
  height: 50px;
  border:  1px solid #000000;
  border-radius: 5px;
}
.next{
  outline: none;
  height: 50px;
  width: 400px;
  border:1px solid #C19B71;
  border-radius: 5px;
  color: #C19B71;
}
.img{
  width: 100px;
  height: 100px;
  background-color: #62b508;
  margin:0px auto;
  line-height: 100px;
}
</style>
